<template>
  <div>
    <v-carousel
      height="700"
      cycle
      class="rounded"
    >
      <v-carousel-item
        v-for="(slide,i) in slides"
        :key="i"
        :src="slide.img"
      />
    </v-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: null,
      slides: [
        { img: '/images/slide1.jpg' },
        { img: '/images/slide2.jpg' },
        { img: '/images/slide3.jpg' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
</style>